const dummyData = {
    serverResponse: null,
    test: "fail",
    caseNameInput: null,
    qtyInput: null,
    currency: "USD",
    cases: [
      {
        name: "Example Case 1",
        qty: 10000,
        prices: [1.3, 1.2, 1.2, 1.3, 1.22, 1.23, 1.3],
        value: 300,
        change: "+200",
        dates: ["2025-01-01", "2025-01-02", "2025-01-03", "2025-01-04", "2025-01-05", "2025-01-06", "2025-01-07"]
      },
      {
        name: "Example Case 2",
        qty: 1000,
        prices: [1.1, 1.2, 1.3, 1.2, 1.2, 1.15, 1.3],
        value: 330.0,
        change: "+20",
        dates: ["2025-01-01", "2025-01-02", "2025-01-03", "2025-01-04", "2025-01-05", "2025-01-06", "2025-01-07"]
      },
    ],
    portfolioValues: [
      630,
      610,
      700,
      620,
      830,
      740,
      850
    ],
    dates: ["2025-01-01", "2025-01-02", "2025-01-03", "2025-01-04", "2025-01-05", "2025-01-06", "2025-01-07"],
    timescale: "7D",
    alerts: {
      duplicateCase: false,
      caseNotFound: false
    },
    emailAlerts: {
      invalidEmail: false,
      emailSubmitted: false,
      emailExists: false
    },
    email: "",
  };

export default dummyData;
import React from "react";
import "./index.css";
import Chart from "./chart";
import PortoflioPanel from "./portfolioPanel";
import caseNames from "./caseNames";
import Alert from '@mui/material/Alert';
import dummyData from './dummyData'
import generateDates from './date_utils'

const BASE_URL = "https://api.casehodler.com";
const PAGE_URL = "https://www.casehodler.com";
const DATES = generateDates('2022-03-14') //first record in DB
console.log(DATES[0], DATES.length)

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.casePricesCache = null;
    this.state = dummyData;
  }

  componentDidMount() {
    document.title = "casehodler";
  }

  handleServerClick = () => {
    fetch(`${BASE_URL}/api`)
      .then((res) => res.json())
      .then((data) => {
        this.setState({test: "success"});
        this.setState({serverResponse: data.message});
      })
  }

  clickTimescale = (timescale) => {
    this.setState({timescale: timescale})
  }

  handleCaseName = (caseNameInput) => {
    this.setState({ 
      caseNameInput: caseNameInput.target.innerText,
      alerts: {
        duplicateCase: false,
        caseNotFound: false,
      }
     });
  };

  handleQty = (qtyInput) => {
    this.setState({ 
      qtyInput: qtyInput.target.value,
      alerts: {
        duplicateCase: false,
        caseNotFound: false,
      }
     });
  };

  handleEmail = (email) => {
    this.setState({
      email: email.target.value,
      emailAlerts: {
        invalidEmail: false,
        emailSubmitted: false,
        emailExists: false,
      }
    });
  }

  
  fetchCasePrices = async(caseName) => {
    return fetch(
      `${BASE_URL}/getCasePrices`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({caseName: caseName}),
      }
    )
    .then((res) => res.json())
    .then((data) => {
        return data;
    });
  };

  fetchPortfolio = async(hash) => {
    return fetch(
      `${BASE_URL}/getPortfolio`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({hash: hash}),
      }
    )
    .then((res) => res.json())
    .then((data) => {
        return data;
    });
  };

  genCaseData = (rows) => {
    // generate prices array and dates array, filling missing days data
    const prices = [];
    let j=0;
    let date = "";
    let db_date="";
    let price = 0;
        
    for(let i=0; i<DATES.length;i++){
      db_date = rows[j].ds.slice(0, 10)
      if (db_date==DATES[i]){
        price = rows[j].buypriceUSD
        j+=1
      } else {
        price = null 
      }
      prices.push(price);
    }

    return prices
  }

  fetchPortfolioCases = async(hash) => {
    const rows = await this.fetchPortfolio(hash);
    let caseNames = [];
    let caseQty = []
    
    //find case names
    for (let i=0;i<rows.length;i++){
      if (caseNames.indexOf(rows[i].item)===-1){
        caseNames.push(rows[i].item);
        caseQty.push(rows[i].qty);
      }
    }
    let cases = [];
    let caseRows = []; 
    for(let i=0; i<caseNames.length;i++){
      caseRows = [];
      for (let j=0; j<rows.length; j++){
        if (rows[j].item === caseNames[i]) {
          caseRows.push(rows[j]) 
        }
      }
      const prices = this.genCaseData(caseRows)
      const pricesLength = prices.length
      cases = cases.concat([{
        name: caseNames[i],
        qty: caseQty[i],
        prices: prices,
        dates: DATES,
        change: this.generateChange2(prices, caseQty[i]),
        value: Number((prices[pricesLength-1]*caseQty[i]).toFixed(3))
      }]);
    }
    
    const portfolioValues = this.calcPortfolioValue(cases, DATES)
    this.setState({
      cases: cases,
      portfolioValues: portfolioValues,
      dates: DATES,
      hash: hash,
    });
  };

  updatePortfolio = async() =>{
    const hash = this.props.user.toString();
    const cases = this.state.cases;
    console.log(hash);
    console.log(cases);
    //1. delete old stored portfolio
    //2. store new portfolio in db
    fetch(
      `${BASE_URL}/updatePortfolio`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          hash: hash,
          cases: cases,
        }),
      }
    )
    .then((res) => res.json())
    .then((data) => {
        return data;
    });
  }

  generateChange = (prices, qty) => {
    const length = prices.length;
    let interval;
    if (this.state.timescale === "7D"){
      interval = 6;
    }else{
      if(this.state.timescale === "30D"){
        interval = 29;
      } else {
        if(this.state.timescale === "90D"){
          interval = 89;
        }
      }
    }
    if (length-1-interval) {}
    const change = prices[length-1]*qty - prices[length-1-interval]*qty;
    let sign;
    if (change > 0){
      sign = "+";
    }else{
      sign = "";
    }
    return sign + Number(change.toFixed(3));
  };
  
  generateChange2 = (prices, qty) => {
    const length = prices.length;
    const change = prices[length-1]*qty - prices[0]*qty;
    let sign;
    if (change > 0){
      sign = "+";
    }else{
      sign = "";
    }
    return sign + Number(change.toFixed(3));
  }
  
  handleAddCase = async() => {
    const name = this.state.caseNameInput;
    let names = [];
    this.state.cases.forEach((c)=>{
      names.push(c.name)
    });

    const cases = this.state.cases;
    
    if (caseNames.includes(name)){
      if (!names.includes(name)){
        const qty = Number(this.state.qtyInput);
        const rows = await this.fetchCasePrices(name);
        
        const prices = this.genCaseData(rows)

        const pricesLength = prices.length
        const value = Number((prices[pricesLength-1]*qty).toFixed(3));
        const change = this.generateChange2(prices, qty);

        const newCases = cases.concat([
          {
            name: name,
            qty: qty,
            prices: prices,
            value: value,
            change: change,
            dates: DATES,
          },
        ]);
        const portfolioValues = this.calcPortfolioValue(newCases);
        this.setState({
           cases: newCases,
           portfolioValues: portfolioValues,
           dates: DATES
          });

      } else {
        this.setState({
          alerts: {
            duplicateCase: true,
            caseNotFound: false,
          }
        });
      }
    } else {
      this.setState({
        alerts: {
          caseNotFound: true, 
          duplicateCase: false,
        }
      });
    }
  };

  deleteCase = (i) => {
    let cases = this.state.cases;
    cases.splice(i, 1);
    this.setState({
      cases: cases,
      portfolioValues: this.calcPortfolioValue(cases),
      dates: DATES
    });
  };

  showAlert = () => {
    if (this.state.alerts.caseNotFound===true){
      return (<div className="alert"><Alert severity="error">Case was not found!</Alert></div>);
    }
    if (this.state.alerts.duplicateCase===true){
      return (<div className="alert"><Alert severity="error">Case already in the list!</Alert></div>);
    }
    if (this.props.user!=="false"){
      return;
    }
    return <div className="placeholder"></div>; 
  };
  
  showEmailAlert = () => {
    if (this.state.emailAlerts.invalidEmail===true){
      return (<Alert severity="error">Invalid Email!</Alert>);
    }
    if (this.state.emailAlerts.emailSubmitted===true){
      return (<Alert severity="success">Email submitted!</Alert>);
    }
    if (this.state.emailAlerts.emailExists===true){
      return (<Alert severity="error">e-mail already registered!</Alert>);
    }
    return
    //return <div className="placeholder"></div>; 
  }

  calcPortfolioValue = (cases) => {

    let portfolioValues = [];
    const dateLengthsArray = cases.map((c)=>
      c.dates.length
    )
    const maxDateLength = Math.max(...dateLengthsArray)
    
    let sum = 0;
    for (let i=0; i<maxDateLength; i++){
      cases.forEach((c)=>{
        if (c.prices[i]!=null){
          // if case does not have value in DB for that day, just do not add anything 
          sum = sum + c.prices[i] * c.qty;
        }
      })
      if (sum == 0){
        sum = null
      }
      portfolioValues.push(sum);
      sum = 0;
    }
    //this.setState({portfolioValues: portfolioValues});
    return portfolioValues
  }

  submitEmail = () => {
    const email = this.state.email;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( re.test(email) ) {
      //this.setState({
      //  alerts: {
      //    invalidEmail: false,
      //    emailSubmitted: false,
      //}
      //});
      const cases = this.state.cases;
      fetch(
        `${BASE_URL}/addEmail`,
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            email: email,
            cases: cases,
          }),
        }
      )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.emailSuccess){
          console.log(data.hash);
          this.setState({
            emailAlerts: {
              invalidEmail: false,
              emailSubmitted: true,
              emailExists: true
            }
          })
          window.location.href=`${PAGE_URL}/user/${data.hash}`;
        }else{
          this.setState({
            emailAlerts: {
              invalidEmail: false,
              emailSubmitted: false,
              emailExists: true
            }
          })
        }
      });
    }
    else {
      this.setState({
        emailAlerts: {
          invalidEmail: true,
          emailSubmitted: false,
          emailExists: true
        }
      });
    }
  }
  
  render() {
    return (
      <div className="main">
        <div className="content">
          <div className="chart">
            <Chart 
              values={this.state.portfolioValues}
              dates={this.state.dates}
            />
          </div>
          <div className="portfoliopanel">
            <PortoflioPanel
              cases={this.state.cases}
              handleAddCase={this.handleAddCase}
              handleCaseName={this.handleCaseName}
              handleQty={this.handleQty}
              deleteCase={this.deleteCase}
              showAlert={this.showAlert}
              handleEmail={this.handleEmail}
              submitEmail={this.submitEmail}
              showEmailAlert={this.showEmailAlert}
              user={this.props.user}props
              fetchPortfolioCases={this.fetchPortfolioCases}
              updatePortfolio={this.updatePortfolio}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
// ==================================
//ReactDOM.render(<Page />, document.getElementById("root"));

function dateDiffInDays(date1, date2) {
  // Get the difference in milliseconds
  const diffTime = Math.abs(date2 - date1);

  // Convert milliseconds to days
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}

function generateDates(startDate) {
  const dates = [];
  const currentDate = new Date(startDate);  // Initialize with the given start date
  const today = new Date()

  for (let i = 0; i < dateDiffInDays(currentDate, today); i++) {
    // Get year, month, and day
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');  // Months are 0-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');  // Ensure two digits

    // Push formatted date in 'YYYY-MM-DD' format
    dates.push(`${year}-${month}-${day}`);
    
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}
export default generateDates;